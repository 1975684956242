import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { Select } from 'element-ui'
import { Option } from 'element-ui'
import { Input } from 'element-ui'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Input.name, Input)
Vue.component(Message.name, Message)

Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
