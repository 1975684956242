import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 's-product',
    name: 'server',
    title: '后台',
    component: () => import('@/views/server/index.vue'),
    children:[
      {
        path: 'home',
        name: 's-home',
        title: '首页',
        component: () => import('@/views/server/home/index.vue'),
      },{
        path: 's-product',
        name: 's-product',
        title: '产品',
        component: () => import('@/views/server/product/index.vue'),
      },{
        path: 's-industry',
        name: 's-industry',
        title: '行业',
        component: () => import('@/views/server/product/industry.vue'),
      },{
        path: 's-cases',
        name: 's-cases',
        title: '案例',
        component: () => import('@/views/server/cases/index.vue'),
      },{
        path: 's-cases-type',
        name: 's-cases-type',
        title: '案例类型',
        component: () => import('@/views/server/cases/type.vue'),
      },{
        path: 's-news',
        name: 's-news',
        title: '新闻',
        component: () => import('@/views/server/news/index.vue'),
      },{
        path: 's-recruit',
        name: 's-recruit',
        title: '职位',
        component: () => import('@/views/server/recruit/index.vue'),
      },{
        path: 's-chron',
        name: 's-chron',
        title: '纪事',
        component: () => import('@/views/server/chron/index.vue'),
      },{
        path: 's-honor',
        name: 's-honor',
        title: '荣誉',
        component: () => import('@/views/server/honor/index.vue'),
      },{
        path: 's-qualify',
        name: 's-qualify',
        title: '资质',
        component: () => import('@/views/server/qualify/index.vue'),
      },{
        path: 's-property',
        name: 's-property',
        title: '专利',
        component: () => import('@/views/server/property/index.vue'),
      },{
        path: 's-property-type',
        name: 's-property-type',
        title: '专利',
        component: () => import('@/views/server/property/type.vue'),
      },{
        path: 's-activity',
        name: 's-activity',
        title: '活动风采',
        component: () => import('@/views/server/activity/index.vue'),
      },{
        path: 's-activity-type',
        name: 's-activity-type',
        title: '活动风采类型',
        component: () => import('@/views/server/activity/type.vue'),
      },{
        path: 's-company',
        name: 's-company',
        title: '合作企业',
        component: () => import('@/views/server/company/index.vue'),
      },{
        path: 's-company-type',
        name: 's-company-type',
        title: '合作企业类型',
        component: () => import('@/views/server/company/type.vue'),
      },{
        path: 's-cases-editor',
        name: 's-cases-editor',
        title: '新增案例',
        component: () => import('@/views/server/editor/cases.vue'),
      },{
        path: 's-news-editor',
        name: 's-news-editor',
        title: '新增新闻',
        component: () => import('@/views/server/editor/news.vue'),
      },{
        path: 's-user',
        name: 's-user',
        title: '用户',
        component: () => import('@/views/server/user/index.vue'),
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    title: '登录',
    component: () => import('@/views/server/login/index.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.path.indexOf('login')!=-1||sessionStorage.getItem('token')!==null){
    next()
  }else{
    console.log('not authorized')
    next({path:'/login'})
  }
})
export default router
